const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Koushik's Portfolio",
  description:
    "A passionate individual, who thrives on creating end to end solutions that push the boundaries of innovation and strengthen the future of technology.",
  og: {
    title: "Koushik Ballari Portfolio",
    type: "website",
    url: "https://koushikballari.com/",
  },
};

//Home Page
const greeting = {
  title: "Koushik Ballari",
  logo_name: "KoushikBallari",
  nickname: "Jr. Ballari",
  subTitle:
    "A passionate individual, who thrives on creating end to end solutions that push the boundaries of innovation and strengthen the future of technology.",
  resumeLink:
    "https://drive.google.com/file/d/1JWKKmMDOxHwjlqSn5g3ytm9IOmTuPjoC/view?usp=sharing",
  portfolio_repository: "https://github.com/master-boop/KoushikBallari.com",
  githubProfile: "https://github.com/master-boop",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/master-boop",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/koushik-ballari-27a97131b/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/@KoushikBallari",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:ballarikoushik@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/koushik._.main/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
        "⚡ Experience working on innovative projects involving interactive AI systems, cybersecurity, and smart automation solutions.",
        "⚡ Proficient in crafting dynamic algorithms for real time data analysis, predictive modeling, and decision making systems.",
      ],
      softwareSkills: [
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "PyTorch",
          fontAwesomeClassname: "logos-pytorch",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Crafting responsive, dynamic web applications with React and Redux for seamless user experiences",
        "⚡ Developing high-performance mobile apps with Flutter and React Native, and building Android apps with Kotlin",
        "⚡ Designing robust backends with Node.js, Express, and Flask to power scalable, efficient applications",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infrastructure & Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Skilled in leveraging top cloud platforms to build scalable, high performance systems",
        "⚡ Hosting and managing websites on virtual machines, with seamless database integration",
        "⚡ Deploying AI and deep learning models to the cloud for mobile and edge device applications",
        "⚡ Expertise in configuring real time data streaming pipelines between databases and servers on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Crafting visually stunning and intuitive user interfaces for mobile and web applications",
        "⚡ Designing custom logos and brand identities from the ground up",
        "⚡ Streamlining application workflows to enhance and optimize user experiences",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "The Ponds HighSchool",
      subtitle: "Graduating in 2026",
      logo_path: "pondshigh.png",
      alt_name: "IIITDM Kurnool",
      duration: "2021 - 2026",
      descriptions: [
        "⚡ I have excelled in subjects like Database Design, Artificial Intelligence, Web Development and Game Development, consistently placing 1st in these areas at school.",
        "⚡ Beyond academics, I have developed strong leadership skills through extracurriculars, where I demonstrated critical thinking, collaboration, and public speaking abilities.",
        "⚡ I am passionate about programming and the future of technology, with a proven record of success across various projects and competitions, showcasing both technical expertise and teamwork.",
      ],
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Google Cybersecurity Professional Certificate",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.credly.com/badges/5de6f9ab-b28e-48c1-b450-ded24da7f91a/linked_in_profile",
      alt_name: "Google",
      color_code: "#8C151599",
    },
    {
      title: "Career Essentials in Generative AI",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/a2d319128366d86addd693aa31b58e29050daed967dfbf4f24c4a5789d898d1c?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bot1YuWhVS%2BuV3gWMeupvsw%3D%3D",
      alt_name: "Microsoft",
      color_code: "#00000099",
    },
    {
      title: "Career Essentials in Cybersecurity",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://www.linkedin.com/learning/certificates/c5142aa0b2733214022ae89229e874d0c98dcc6de4a4b87fcf6d90e033c67c88?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bot1YuWhVS%2BuV3gWMeupvsw%3D%3D",
      alt_name: "Microsoft",
      color_code: "#0C9D5899",
    },
    {
      title: "Introduction to Artificial Intelligence",
      logo_path: "tafe_logo.png",
      certificate_link:
        "https://training.tafensw.edu.au/badges/badge.php?hash=c4509f2b83c3afab35b3e1de977013dd3e67967b",
      alt_name: "Tafe",
      color_code: "#1F70C199",
    },
    {
      title: "Generative AI and its Business Applications",
      logo_path: "tafe_logo.png",
      certificate_link:
        "https://training.tafensw.edu.au/badges/badge.php?hash=eca3c1e5ea2559b04c64c9b9c643ec61eb39fe85",
      alt_name: "Tafe",
      color_code: "#D83B0199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I’ve had the opportunity to work with a range of innovative startups and well known companies, contributing as a AI/ML Developer, Cybersecurity Specialist, Designer, and Software Architect. My expertise spans from building cutting edge AI solutions to ensuring robust cybersecurity frameworks. Alongside my technical roles, I’m passionate about organizing events and fostering collaboration. This passion has led me to actively represent and contribute to various open source communities, where I help bring people together to innovate ",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "IT Support Specialist",
          company: "1Cyber",
          company_url: "https://1cyber.com.au/",
          logo_path: "1Cyber_logo.png",
          duration: "August 2024 - August 2024",
          location: "Sydney, Australia",
          description:
            "During my time at 1Cyber, I gained hands on exposure to real world IT infrastructure and cybersecurity. Mentored by industry professionals, I learned to manage Azure directories, handle institution-wide email systems, and automate workflows using Power Automate. I also explored networking, working with on-premise servers, and was introduced to hypervisors and virtualized environments. This experience strengthened my understanding of cloud services, cybersecurity protocols, and IT support operations, equipping me with practical skills in managing modern digital ecosystems.",
          color: "#000000",
        },
      ],
    },

    {
      title: "Volunteerships",
      experiences: [
        {
          title: "The Vinnies",
          company: "St Vincent De Paul",
          company_url: "https://www.vinnies.org.au/",
          logo_path: "Vinnies_logo.png",
          duration: "June 2018 - Decemember 2020",
          location: "Sydney, Australia",
          description:
            "During primary school, I volunteered at St. Vincent de Paul (Vinnies), where I played an active role in organizing and distributing hampers for those in need. As part of this initiative, I worked alongside a team to gather essential items, package them into hampers, and personally distribute them to less fortunate individuals and families. This experience not only taught me the value of community service but also developed my organizational and teamwork skills while making a meaningful impact in the lives of others.",
          color: "#4285F4",
        },
        {
          title: "St Patrick's Day Festival",
          company: "St Patrick's Festival",
          company_url: "https://stpatricksfestival.ie/participate/volunteers",
          logo_path: "St_Patricks_Day_Logo.png",
          duration: "March 2019 - June 2019",
          location: "Sydney, Australia",
          description:
            "In primary school, my school participated in the annual St. Patrick's Day Festival, which served as a major fundraising event for charitable causes. I took on a leadership role, organizing various activities and rallying my peers to contribute to the effort. From coordinating events to promoting the fundraiser within the school community, I played a key role in ensuring its success. Through teamwork and creative engagement, we were able to raise significant funds, which were donated to support various local causes. This experience helped me develop my leadership and communication skills while fostering a sense of responsibility and community spirit.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects leverage cutting edge technology, integrating advanced tools across various domains. I specialize in creating sophisticated end to end solutions, seamlessly deploying them to web applications using robust cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "KoushikBall.png",
    description:
      "I am active across various social media platforms and always open for inquiries. Feel free to reach out, and I will respond within 24 hours. I specialize in Cybersecurity, Cloud Computing, AI, and I'm happy to offer support or guidance in these areas.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I enjoy documenting my journey throughout my career, sharing insights from my professional experiences, and contributing technical knowledge to help others. By reflecting on key milestones and offering practical guidance, I aim to inspire and support those navigating similar paths in technology and development.",
    link: "https://www.linkedin.com/in/koushik-ballari-27a97131b/recent-activity/all/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Sydney, Australia",
    locality: "",
    country: "Australia",
    region: "Sydney",
    postalCode: "2052",
    streetAddress: "Sydney Harbour",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/8nJfvQzzhdgPrPWM9",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
